//翻译国家枚举
export function enumeration(code) {
    let res = ''
    switch (code) {
        case 'CN':
            res = '中国'
            break;
        case 'GB':
            res = '英国'
            break;
        case 'DE':
            res = "德国"
            break;
        case 'PL':
            res = "波兰"
            break;
        case 'IT':
            res = '意大利'
            break;
        case 'PT':
            res = '葡萄牙'
            break;
        case 'FR':
            res = '法国'
            break;
        case 'NL':
            res = '荷兰'
            break;
        case 'ES':
            res = '西班牙'
            break;
    }
    return res;
}
export function enumerationLang(code) {
    let res = ''
    switch (code) {
        case 'zh':
            res = '汉语'
            break;
        case 'en':
            res = '英语'
            break;
        case 'de':
            res = "德国"
            break;
        case 'pl':
            res = "波兰"
            break;
        case 'it':
            res = '意大利'
            break;
        case 'pt':
            res = '葡萄牙'
            break;
        case 'fr':
            res = '法国'
            break;
        case 'nl':
            res = '荷兰'
            break;
        case 'es':
            res = '西班牙'
            break;
    }
    return res;
}
/**
 * 获取文件后缀名
 * @param filename
 * @returns {string}
 */
export function getSuffix(filename) {
    console.info(filename);
    let pos = filename.lastIndexOf(".");
    let suffix = "";
    if (pos != -1) {
        suffix = filename.substring(pos);
    }
    return suffix;
}
export function randomString(len = 32) {
    let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    let maxPos = chars.length;
    let pwd = "";
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}
export function blobToFile(blob, fileName) {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
}